import React from "react";
import Layout from "../components/layout-contents";
import styles from "../styles/review.module.css";
import Container from "../components/container";
import { graphql } from "gatsby";

const User = (props) => (
  <div className={styles.topUser}>
    <div className={styles.descUser}>
      <img
        src="/images/about/giovani/path374-9.png"
        className={styles.logoUser}
        alt=""
      />
      <p className={styles.excerpt}>
      Poet and situationist, GdM studied literature in Mexico City where he grew up. 
      Now living in the art communes of Brussels where he immerses himself in his verses for the lonely and hopeful.
      </p>
      <div className={styles.linkUser} >
        <h3 style={{ marginLeft: "0px" , marginBottom: "0px"}} ><a href="mailto:giovanivaldez@gmail.com ">CONTACT</a></h3>
        <h3 style={{ marginLeft: "calc(1rem + 4.5vmin)" ,marginBottom: "0px"}}><a href="https://codafanzine.bandcamp.com/merch/giovani-della-manchas-anfibio-en-transformaci-n" target="_blank" rel="noreferrer">SHOP</a></h3>
      </div>
    </div>
    <a href={props.file} target="_blank" rel="noreferrer" style={{ display: "contents" }}>
      <img
        src="/images/about/giovani/giovanidellamancha_foto.png"
        className={styles.imageUser}
        alt=""
      />
    </a>
  </div>
);

const Post = (props) => (
  <div style={{  marginBottom:"70px"}}>
    
        <p style={{ whiteSpace: "pre-line"}} >
        <b>{props.title}</b> <br /> <br />
          {props.excerpt}</p>
    </div>
);

/*
  <h1>About CSS Modules</h1>
    <p>CSS Modules are cool</p>
*/
export default ({ data }) => (
  <Layout>
    <Container>
      <User></User>
      {data.allGiovaniJson.edges.map(({ node }) => (
        <Post
          title={node.title}
          excerpt={node.excerpt}
        />
      ))}
    </Container>
  </Layout>
);

export const query = graphql`
  query {
    allGiovaniJson {
      edges {
        node {
          title
          excerpt
        }
      }
    }
  }
`;
